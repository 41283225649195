<template>
  <v-row>
    <v-col cols="12">
      <WeldersSearch v-model="value.weldersIds"/>
    </v-col>
    <v-col cols="4">
      <v-text-field 
        label="Подразделение" 
        :hide-details="true" 
        v-model="value.welderSubdevision"
      />
    </v-col>
    <v-col cols="4">
      <v-text-field 
        label="ID группы" 
        :hide-details="true" 
        v-model="value.processGroup"
      />
    </v-col>
    <v-col cols="4">
      <v-text-field 
        label="ID процесса" 
        :hide-details="true" 
        v-model="value.processName"
      />
    </v-col>
    <v-col cols="2">
      <v-menu
        v-model="startDateMenu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
        >
        <template v-slot:activator="{ on }">
          <v-text-field
            label="С"
            v-model="formatedStartDate"
            readonly
            v-on="on"
            :hide-details="true"
            >    
          </v-text-field>
        </template>
        <v-date-picker 
          v-model="startDate" 
          no-title 
          @input="startDateInput">
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="2">
      <v-menu
        v-model="stopDateMenu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
        >
        <template v-slot:activator="{ on }">
          <v-text-field
            label="По"
            v-model="formatedStopDate"
            readonly
            v-on="on"
            :hide-details="true"
            >    
          </v-text-field>
        </template>
        <v-date-picker 
          v-model="stopDate" 
          no-title 
          @input="stopDateInput">
        </v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import WeldersSearch from './WeldersSearch.vue'

export default {
  components: {
    WeldersSearch,
  },
  props: {
    value: {
      type: Object,
      reuired: true
    }
  },
  data: () => ({
    startDateMenu: false,
    startDate: '',
    stopDateMenu: false,
    stopDate: '',
  }),
  computed: {
    formatedStartDate() {
      return this.formatDate(this.startDate)
    },
    formatedStopDate() {
      return this.formatDate(this.stopDate)
    }
  },
  methods: {
    startDateInput(v) {
      this.value.startDate = (new Date(v)) / 1000
      this.startDateMenu = false
    },
    stopDateInput(v) {
      this.value.stopDate = (new Date(v)) / 1000
      this.stopDateMenu = false
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`      
    },
  },
  watch: {
    value(v) {
      if (!v.startDate) {
        this.startDate = ''
      }
      if (!v.stopDate) {
        this.stopDate = ''
      }
    }
  }
}
</script>