<template>
	<v-autocomplete
		label="ФИО сварщика(ов)"
		:value="selectedProfiles"
		:search-input.sync="searchProfile"
		:items="profiles"
		item-text="name"
		autocomplete="off"
		return-object
		hide-selected
		hide-no-data
		:hide-details="true"
		chips
		deletable-chips
		multiple
		@change="change"
		>
	</v-autocomplete>
</template>

<script>
export default {
	props: {
		value: {
			type: Array,
			reuired: true
		}
	},
	data: () => ({
		searchProfile: '',
		profiles: [],
		apiTimeout: null,
		selectedProfiles: []
	}),
	methods: {
		queryWelder(v) {
			this.axios.get('/user/profile/search', { params: { v: v }})
			.then(r => {
				this.profiles = this.profiles.concat(r.data.slice())
			}).catch(() => {}).finally(() => {})
		},
		change(v) {
			this.searchProfile = ''
			this.profiles = v.slice()
			this.selectedProfiles = v.slice()
			this.$emit('input', v.map(c => c.id))
		}
	},
	watch: {
		searchProfile(v) {
			if(!v || v.length < 2 || v === this.searchProfile.name) {
				return
			}
			clearTimeout(this.apiTimeout)
			this.apiTimeout = setTimeout(() => this.queryWelder(v), 500)
		},		
	}
	
}
</script>