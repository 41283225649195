<template>
  <v-card class="ma-5">
    <v-card-title class="primary justify-center white--text">
      Отчет по персоналу
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-expansion-panels v-model="filterPanels" multiple accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <template v-slot:default>
                <div class="subheader text-center">
                  Основной фильтр
                </div>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <MainFilter v-model="filter"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <template v-slot:default>
                <div class="subheader text-center">
                  Фильтр по картам
                </div>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <CardsFilter v-model="filter"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn color="info" @click="clearFilter">Очистить</v-btn>
        <v-btn color="success" @click="getReport">Применить</v-btn>
      </v-card-actions>
      <div v-if="loaded">
        <v-container  class="ma-4 bordered" style="width: auto;">
          <div class="title text-center">Общаяя статистика</div>
          <v-simple-table class="text-center">
            <template v-slot:default>
              <thead>
                <tr>
                  <td>
                    Кол-во сварщиков в отчёте
                  </td>
                  <td>
                    Выполнено сварных швов(техкарт)
                  </td>
                  <td>
                    Суммарное время выполнения операций
                  </td>
                  <td>
                    Суммарное время сварки
                  </td>
                  <td>
                    Среднее критическое сводное отклонение от режима
                  </td>
                  <td>
                    Среднее общее сводное отклонение от режима
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{mainStatistic.weldersCount}}</td>
                  <td>{{mainStatistic.cardsCount}}</td>
                  <td>{{secToHoursTime(mainStatistic.sumTime)}}</td>
                  <td>{{secToHoursTime(mainStatistic.sumWeldTime/1000)}}</td>
                  <td>{{
                    toPercentString(
                      mainStatistic.sumDevCrit,
                      mainStatistic.sumWeldTime
                    )}}</td>
                  <td>{{
                    toPercentString(
                      mainStatistic.sumDevCommon,
                      mainStatistic.sumWeldTime
                    )}}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-container>
        <v-container class="ma-4 bordered" style="width: auto;">
          <div class="title text-center">Сатистика сварщиков</div>
          <v-simple-table class="text-center">
            <template v-slot:default>
              <thead>
                <tr>
                  <td>
                    Ф.И.О. сварщика
                  </td>
                  <td>
                    Клеймо сварщика
                  </td>
                  <td>
                    Выполнено сварных швов
                  </td>
                  <td>
                    Суммарное время выполнения операций
                  </td>
                  <td>
                    Суммарное время сварки
                  </td>
                  <td>
                    Критическое сводное отклонение от режима
                  </td>
                  <td>
                    Общее сводное отклонение от режима
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="card in procCards" @click="goViewWelder(card.welderId)">
                  <td>{{getWelderName(card.welderId)}}</td>
                  <td>{{getWelderKey(card.welderId)}}</td>
                  <td>{{card.cardsCount}}</td>
                  <td>{{secToHoursTime(card.sumTime)}}</td>
                  <td>{{secToHoursTime(card.sumWeldTime/1000)}}</td>
                  <td>{{toPercentString(card.sumDevCrit, card.sumWeldTime)}}</td>
                  <td>{{toPercentString(card.sumDevCommon, card.sumWeldTime)}}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-container>
        <v-pagination
          class="mt-3"
          v-model="page"
          :length="pagesTotal"
          >
        </v-pagination>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import MainFilter from './components/MainFilter.vue'
import CardsFilter from './components/CardsFilter.vue'
import { toPercentString } from '@/libs/common.js'
import { secToHoursTime } from '@/libs/time.js'
import { mapState } from 'vuex'

export default {
  components: { MainFilter, CardsFilter },
  data: () => ({
    toPercentString,
    secToHoursTime,
    filterPanels: [0],
    filter: {
      weldersIds: [],
    },
    procCards: [],
    welders: [],
    mainStatistic: {},
    loaded: false,
    perPage: 15,
    pagesTotal: 0,
    page: 1,
  }),
  computed: {
    ...mapState('organization', { orgId: state => state.profile.id })
  },
  methods: {
    goViewWelder(welderId) {
      let filter = this.filter
      delete filter.weldersIds
      delete filter.welderSubdevision
      this.$router.push({
        path: '/manufacture/report/staff/view',
        query: { welderId: welderId, ...this.filter }
      })
    },
    getWelderName(welderId) {
      let w = this.welders.find(w => Number(w.userId) === Number(welderId))
      if (w) {
        return w.firstname + ' ' + w.middlename + ' ' + w.lastname
      } else {
        return '?'
      }
    },
    getWelderKey(welderId) {
      let w = this.welders.find(w => Number(w.userId) === Number(welderId))
      if (w) {
        return w.welderKey
      } else {
        return '?'
      }
    },
    getReport() {
      let url = '/manufacture/report/staff/index'
      this.$axios.get(url, {
        params: {
          orgId: this.orgId,
          ...this.filter,
          page: this.page,
          pageSize: this.perPage,
       }
      }).then(r => {
        this.procCards = r.data.items.slice()
        this.mainStatistic = r.data.mainStatistic
        this.pagesTotal = r.data.pagination.pagesTotal
        let welders = []
        this.procCards.forEach(pc => {
          welders.push(pc.welderId)
        })
        this.$axios.get('/user/profile/welders-info', {
          params: { ids: welders }
        }).then(r => {
          this.welders = r.data.slice()
        }).catch(() => {}).finally(() => { this.loaded = true })
      }).catch(() => {}).finally(() => {})
    },
    clearFilter() {
      this.filter = { weldersIds: [] }
    }
  },
  watch: {
    page(newPage) {
      this.getReport()
    }
  },

}
</script>

<style>
  .bordered {
    border-style: solid;
    border-width: thin;
  }
</style>
