<template>
  <v-row>
    <v-col cols="4">
      <v-text-field 
        label="Способ сварки" 
        :hide-details="true"
        v-model="value.weldMethod"
      />
    </v-col>
    <v-col cols="4">
      <v-text-field 
        label="Вид соединяемых элементов" 
        :hide-details="true"
        v-model="value.elementsType"
      />
    </v-col>
    <v-col cols="4">
      <v-text-field 
        label="Тип шва" 
        :hide-details="true"
        v-model="value.seamType"
      />
    </v-col>
    <v-col cols="4">
      <v-text-field 
        label="Тип соединения" 
        :hide-details="true"
        v-model="value.jointType"
      />
    </v-col>
    <v-col cols="4">
      <v-text-field 
        label="Материал элементов" 
        :hide-details="true"
        v-model="value.baseMaterial"
      />
    </v-col>
    <v-col cols="4">
      <v-text-field 
        label="Диаметр элемента 1" 
        :hide-details="true"
        v-model="value.d1"
      />
    </v-col>
    <v-col cols="4">
      <v-text-field 
        label="Толщина элемента 1" 
        :hide-details="true"
        v-model="value.d2"
      />
    </v-col>
    <v-col cols="4">
      <v-text-field 
        label="Диаметр элемента 2" 
        :hide-details="true"
        v-model="value.s1"
      />
    </v-col>
    <v-col cols="4">
      <v-text-field 
        label="Толщина элемента 2" 
        :hide-details="true"
        v-model="value.s2"
      />
    </v-col>
    <v-col cols="4">
      <v-text-field 
        label="Сварочный материал" 
        :hide-details="true"
        v-model="value.weldMaterial"
      />
    </v-col>
    <v-col cols="4">
      <v-text-field 
        label="Положение в пространстве" 
        :hide-details="true"
        v-model="value.weldPosition"
      />
    </v-col>    
  </v-row>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      reuired: true
    }
  }
}
</script>